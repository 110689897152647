import { defineStore } from 'pinia';
import { useStorage, StorageSerializers } from '@vueuse/core';
import { UserSchema } from '@/api';

export const useAuthStore = defineStore('auth', () => {
    const user = useStorage<UserSchema | null>('user', null, localStorage, {
        mergeDefaults: true,
        serializer: StorageSerializers.object,
    });
    const token = useStorage<string>('token', '', localStorage, {
        mergeDefaults: true,
        serializer: StorageSerializers.string,
    });

    const isLoggedIn = computed(() => !!user.value && !!token.value);

    function signout() {
        user.value = null;
        token.value = null;
    }

    return { user, token, signout, isLoggedIn };
});
