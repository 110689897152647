import http from '@/js/http';
import { ref, computed } from 'vue';

const data = ref(null);
const loaded = ref(false);
const ts = ref(now());

function now() {
    return new Date().getTime();
}

export function useCategory() {
    const update = (payload) => {
        data.value = payload;
    };
    const fetch = async () => {
        if (loaded.value && now() - ts.value < 5 * 60 * 1000) {
            return;
        }
        try {
            const response = await http.get(`/categories`);
            update(response.data);
            loaded.value = true;
            ts.value = now();
        } catch (err) {
            console.error(err);
        }
    };
    const categories = computed(() => {
        const items = JSON.parse(JSON.stringify(data.value || []));
        const root = [];
        const lookup = {};
        for (const item of items) {
            const id = item.id;
            const parentId = item.parent_id;

            if (!lookup[id]) lookup[id] = { ['children']: [] };

            lookup[id] = { ...item, ['children']: lookup[id]['children'] };

            const TreeItem = lookup[id];

            if (!parentId) {
                root.push(TreeItem);
            } else {
                if (!lookup[parentId]) lookup[parentId] = { ['children']: [] };

                lookup[parentId]['children'].push(TreeItem);
            }
        }
        return root;
    });

    const getCategory = (id) => {
        if (!data.value) {
            return null;
        }
        return data.value.find((x) => x.id == id);
    };

    const getCategoryPath = (id) => {
        let c = getCategory(id);
        let path = [];
        while (c) {
            path.push(c.title);
            c = c.parent_id ? getCategory(c.parent_id) : null;
        }
        return path.reverse();
    };

    return {
        categories,
        getCategory,
        getCategoryPath,
        update,
        fetch,
    };
}
