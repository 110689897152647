export function isDown() {
    if (!location.host.includes('housecareonline.in') && !(window as any).Cypress) return false;
    const start = 0 * 60 + 0;
    const end = 0 * 60 + 15;
    const d = new Date();
    const utc = d.getTime() + d.getTimezoneOffset() * 60000;
    const now = new Date(utc + 3600000 * 5.5);
    const stamp = now.getHours() * 60 + now.getMinutes();
    return stamp >= start && stamp <= end;
}
