import axios from 'axios';
import router from '@/router';
import bus from '@/js/bus';
import { useAuthStore } from '@/stores/auth';

const MAX_CONCURRENCY = 2;
let running = 0;

const http = axios.create({
    baseURL: `${import.meta.env.VITE_API_URL}/api`,
    timeout: 60 * 4 * 1000,
    headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Version': APP_VERSION,
    },
});

http.interceptors.request.use((config) => {
    return new Promise((resolve) => {
        const auth = useAuthStore();
        if (running < MAX_CONCURRENCY) {
            running++;
            document.body.classList.add('is-busy');
            if (!config.headers) config.headers = {};
            if (auth.token) {
                config.headers['Authorization'] = 'Bearer ' + auth.token;
            }
            return resolve(config);
        }

        function acquire() {
            if (running < MAX_CONCURRENCY) {
                running++;
                document.body.classList.add('is-busy');
                if (!config.headers) config.headers = {};
                if (auth.token) {
                    config.headers['Authorization'] = 'Bearer ' + auth.token;
                }
                bus.off('http:release', acquire);
                return resolve(config);
            }
        }
        bus.on('http:release', acquire);
    });
});

http.interceptors.response.use(
    (response) => {
        document.body.classList.remove('is-busy');
        running = Math.max(0, running - 1);
        bus.emit('http:release');
        return response;
    },
    (error) => {
        const auth = useAuthStore();
        document.body.classList.remove('is-busy');
        if (error?.response?.status == 401) {
            auth.signout();
            router.replace({ name: 'login', query: { continue: window.location.pathname } });
        }
        running = Math.max(0, running - 1);
        bus.emit('http:release');
        return Promise.reject(error);
    }
);

export default http;
