import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createHead } from '@vueuse/head';
import i18n from './i18n';

import '@unocss/reset/tailwind.css';
import 'virtual:uno.css';
import './index.scss';

// import { AxiosError } from 'axios';
// import http from './js/http';
import { createPinia } from 'pinia';
// import { useAuthStore } from '@/stores/auth';
import * as Sentry from '@sentry/vue';

let storageSupported = false;
try {
    storageSupported = window.localStorage && true;
} catch (e) {}

if (storageSupported) {
    const head = createHead({
        titleTemplate: '%s - House Care Partner',
    });

    const pinia = createPinia();
    const app = createApp(App);

    import.meta.env.VITE_SENTRY_DSN &&
        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration({ maskAllText: false, maskAllInputs: false })],
            tracesSampleRate: 1.0,
            tracePropagationTargets: ['localhost', /^https:\/\/([a-z]+\.)*housecareonline\.in\/api/],
            replaysSessionSampleRate: 0.0,
            replaysOnErrorSampleRate: 1.0,
        });

    app.use(pinia).use(router).use(head).use(i18n).mount('#app');

    if ('serviceWorker' in navigator && location.host.includes('housecareonline.in')) {
        window.addEventListener('load', async () => {
            try {
                let registration = await navigator.serviceWorker.register('/sw.js');

                let refreshing = false;
                navigator.serviceWorker.addEventListener('controllerchange', () => {
                    if (!refreshing) {
                        window.location.reload();
                        refreshing = true;
                    }
                });

                registration.update();
            } catch (e) {
                console.error(e);
            }
        });
    }
} else {
    alert('LocalStorage is not supported. Please upgrade to a newer browser.');
}

// app.config.errorHandler = async (err, _vm) => {
//     if (!(err instanceof AxiosError)) {
//         const auth = useAuthStore();
//         console.error(err);
//         const body = (err as Error).stack || (err as Error).toString();
//         await http.post(`/error`, {
//             body,
//             location: window.location.href,
//             user: auth.user
//                 ? {
//                       id: auth.user.id,
//                       name: auth.user.name,
//                       roles: (auth.user.roles || []).map((x) => x.slug),
//                   }
//                 : {},
//         });
//     }
// };
