import mitt, { Emitter } from 'mitt';

type Events = {
    'page:title': string;
    [k: string]: any;
};

const bus: Emitter<Events> = mitt<Events>();

export default bus;
