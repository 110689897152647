import { createI18n } from 'vue-i18n';
import en from '@/locales/en.json';

type DatetimeSchema = {
    short: {
        year: 'numeric';
        month: 'short';
        day: 'numeric';
    };
    long: {
        year: 'numeric';
        month: 'short';
        day: 'numeric';
        weekday: 'short';
        hour: 'numeric';
        minute: 'numeric';
    };
};

type NumberSchema = {
    currency: {
        style: 'currency';
        currency: 'INR';
        notation: 'standard';
    };
    decimal: {
        style: 'decimal';
        minimumFractionDigits: 2;
        maximumFractionDigits: 2;
    };
    percent: {
        style: 'percent';
        useGrouping: false;
    };
};

type MessageSchema = typeof en;

const i18n = createI18n<{ message: MessageSchema; datetime: DatetimeSchema; number: NumberSchema }, 'en', false>({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en,
    },
    datetimeFormats: {
        en: {
            short: {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
            },
            long: {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
                weekday: 'short',
                hour: 'numeric',
                minute: 'numeric',
            },
        },
    },
    numberFormats: {
        en: {
            currency: {
                style: 'currency',
                currency: 'INR',
                notation: 'standard',
            },
            decimal: {
                style: 'decimal',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            },
            percent: {
                style: 'percent',
                useGrouping: false,
            },
        },
    },
    legacy: false,
});

export default i18n;
