<script setup lang="ts">
import { UserSchema } from './api';
import http from './js/http';
import { useAuthStore } from './stores/auth';

const auth = useAuthStore();
async function loadUser() {
    try {
        const { data } = await http.get<UserSchema>('/user');
        auth.user = data;
    } catch (e) {
        console.error(e);
    }
}
auth.isLoggedIn && loadUser();
</script>

<template>
    <!-- <router-view v-slot="{ Component, route }">
        <transition :name="route.meta.transition as string || 'fade'">
            <component :is="Component" />
        </transition>
    </router-view> -->
    <RouterView />
    <ToastContainer />
</template>
