import http from '@/js/http';
import { computed, reactive, ref } from 'vue';

const data = ref([]);
const loaded = ref(false);
const ts = ref(now());

function now() {
    return new Date().getTime();
}

export function useLocation() {
    const update = (payload) => {
        data.value = payload;
    };
    const fetch = async () => {
        if (loaded.value && now() - ts.value < 5 * 60 * 1000) {
            return;
        }
        try {
            const response = await http.get(`/locations`);
            update(response.data);
            loaded.value = true;
            ts.value = now();
        } catch (err) {
            console.error(err);
        }
    };

    const clone = (v) => {
        return JSON.parse(JSON.stringify(v));
    };

    const tree = (items = []) => {
        const root = [];
        const lookup = {};
        for (const item of items) {
            const id = item.id;
            const parentId = item.parent_id;

            if (!lookup[id]) lookup[id] = { ['children']: [] };

            lookup[id] = { ...item, ['children']: lookup[id]['children'] };

            const TreeItem = lookup[id];

            if (!parentId) {
                root.push(TreeItem);
            } else {
                if (!lookup[parentId]) lookup[parentId] = { ['children']: [] };

                lookup[parentId]['children'].push(TreeItem);
            }
        }
        return root;
    };

    const locations = computed(() => {
        return tree(clone(data.value || []));
    });

    const states = computed(() => {
        const items = (data.value || []).filter((x) => x.type == 'state');
        return tree(clone(items));
    });

    const districts = computed(() => {
        const items = (data.value || []).filter((x) => ['state', 'district'].includes(x.type));
        return tree(clone(items));
    });

    const areas = computed(() => {
        const items = (data.value || []).filter((x) => ['state', 'district', 'area'].includes(x.type));
        return tree(clone(items));
    });

    const pincodes = computed(() => {
        const pincodes = (data.value || []).filter((x) => x.type == 'pincode');
        const areas = (data.value || []).filter((x) => x.type == 'area');
        const districts = (data.value || []).filter((x) => x.type == 'district');
        const states = (data.value || []).filter((x) => x.type == 'state');
        return pincodes.map((p) => {
            let area = areas.find((a) => a.id == p.parent_id);
            let district = area ? districts.find((d) => d.id == area.parent_id) : null;
            let state = district ? states.find((s) => s.id == district.parent_id) : null;
            return {
                pincode: p.title,
                area: area?.title,
                district: district?.title,
                state: state.title,
            };
        });
    });

    const getLocation = (id, type) => {
        return data.value?.find((x) => x.id == id);
    };

    return {
        locations,
        getLocation,
        states,
        districts,
        areas,
        pincodes,
        update,
        fetch,
        loaded: computed(() => loaded.value),
    };
}
